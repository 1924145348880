import { pushEvent } from "@wbly/common";
import type { DataLayer } from "@wbly/common";

export const trackNewsletterSignUp = (success: string) =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    `homepage`,
    `sign_up_newsletter`,
    `${success}` && "Completed"
  );
